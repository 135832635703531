"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS, getErrorMessage } from "../../global/index";
const {
  GETTING_INFO,
  GET_INFO_SUCCESS,
  SET_HISTORY_OPTION,
  SET_ADDED,
  SET_STATUS,
  ERROR_MSG,
  ERROR_STATUS,
} = CONSTANTS;
const ERROR_OBJ = {
  success: false,
  status: ERROR_STATUS,
  code: 500,
  message: ERROR_MSG,
  data: null
};
export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
    historyOption: "Default",
    status: "",
    added: "",
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
    getHistoryOption(state) {
      return state.historyOption;
    },
    getAdded(state) {
      return state.added;
    },
    status(state) {
      return state.status;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [SET_HISTORY_OPTION](state, change) {
      state.historyOption = change;
    },
    [SET_ADDED](state, change) {
      state.added = change;
    },
    [SET_STATUS](state, value) {
      state.status = value;
    },
  },
  actions: {
    setAddedPrs({ commit }, payload) {
      commit(SET_ADDED, payload);
    },

    setHistoryOptionPrs({ commit }, payload) {
      commit(SET_HISTORY_OPTION, payload);
    },

    async getAllInfoPrs({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getAllInfo(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.data);

        return response.data.data;
      } catch (error) {
        commit(GET_INFO_SUCCESS, []);
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async getInfoByIdPrs({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.data);

        return response.data.data;
      } catch (error) {
        commit(GET_INFO_SUCCESS, []);
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async getByDatePrs({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getByDate(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.data);

        return response.data.data;
      } catch (error) {
        commit(GET_INFO_SUCCESS, []);
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async deleteItemPrs({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.deleteItem(payload, headers);
        return response.data;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },
    async editItemPrs({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.editItem(payload, headers);
        return response.data;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async addItemPrs({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.addItem(payload, headers);

        return response.data;
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },
  },
};
