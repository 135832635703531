'use strict'

import api from '@/api/api';
import userService from "../../services/user.service";
import { CONSTANTS, getErrorMessage } from "../../global/index";
 
const { 
        SET_STATUS,
        GETTING_INFO,
        GET_INFO_SUCCESS,
        SET_HISTORY_OPTION,
        SET_ADDED,
        ERROR_MSG,
        ERROR_STATUS,
        GETTING_CAT,
        GETTING_SUB,
        GETTING_CLA,
        GETTING_PAC,
        SET_CATEGORIES,
        SET_SUBCATEGORIES,
        SET_CLASSES,
        SET_PACKAGINGS,
        GETTING_CAR,
        GETTING_QUO,
        SET_CARRIERS,
        SET_QUOTATIONS
} = CONSTANTS;

const ERROR_OBJ = {
    success: false,
    status: ERROR_STATUS,
    code: 500,
    message: ERROR_MSG,
    data: null
};

export default {
    namespaced: true,
    state:{
        status: ERROR_STATUS,
        data: [],
        added:'',
        historyOption: 'Default',
        categories:[],
        subcategories:[],
        classes:[],
        packagings:[],
        carriers:[],
        quotations:[],
    },
    getters: {
        status(state) {
            return state.status;
        },
        data(state) {
            return state.data;
        },
        categories(state) {
            return state.categories;
        },
        subcategories(state) {
            return state.subcategories;
        },
        classes(state) {
            return state.classes;
        },
        packagings(state) {
            return state.packagings;
        },
        carriers(state) {
            return state.carriers;
        },
        quotations(state) {
            return state.quotations;
        }, 
        getAdded(state){
            return state.added
        },
        getHistoryOption(state){
            return state.historyOption
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.data = change;
        },
    
        [SET_ADDED](state, change){
            state.added = change;
        },

        [GETTING_CAT](state){
            state.categories = [];
        },
        [GETTING_SUB](state){
            state.subcategories = [];
        },
        [GETTING_CLA](state){
            state.classes = [];
        },
        [GETTING_PAC](state){
            state.packagings = [];
        },
        [GETTING_CAR](state){
            state.carriers = [];
        },
        [GETTING_QUO](state){
            state.quotations = [];
        },
        [SET_CATEGORIES](state, change){
            state.categories = change;
        },
        [SET_SUBCATEGORIES](state, change){
            state.subcategories = change;
        },
        [SET_CLASSES](state, change){
            state.classes = change;
        },
        [SET_PACKAGINGS](state, change){
            state.packagings = change;
        },
        [SET_QUOTATIONS](state, change){
            state.quotations = change;
        },
        [SET_CARRIERS](state, change){
            state.carriers = change;
        },
        [SET_HISTORY_OPTION](state, change){
            state.historyOption = change;
        },
        [SET_STATUS](state, value) {
            state.status = value;
        },  
    },
    actions:{
        setAddedSmt({commit}, payload){
            commit(SET_ADDED, payload);  
        },

        setHistoryOptionSmt({commit}, payload){
            commit(SET_HISTORY_OPTION, payload);  
        },

        async getAllInfoSmt({ commit }, payload) {
            commit(GETTING_INFO);

            try {
              const token = userService.getToken();
              const headers = { Authorization: token };
              const response = await api.getAllInfo(payload, headers);
              commit(GET_INFO_SUCCESS, response.data.data);
      
              return response.data;
            } catch (error) {
              commit(GET_INFO_SUCCESS, []);
              if(!error?.response?.data?.message) return  ERROR_OBJ;
              return getErrorMessage(error.response.data.message)
            }
        },
      
        async getInfoByIdSmt({ commit }, payload) {
            commit(GETTING_INFO);
            try {
              const token = userService.getToken();
              const headers = { Authorization: token };
              const response = await api.getInfoById(payload, headers);
              commit(GET_INFO_SUCCESS, response.data.data);
      
              return response.data.data;
            } catch (error) {
              commit(GET_INFO_SUCCESS, []);
              if(!error?.response?.data?.message) return  ERROR_OBJ;
              return getErrorMessage(error.response.data.message)
            }
        },


        async getPackagings({ commit }) {
            commit(GETTING_PAC);
            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getConsignmentNotePackagings( headers);

                response.data.success ? commit(SET_PACKAGINGS, response.data.data) : commit(SET_PACKAGINGS, [])
                    
                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async getCategories({ commit }) {
            commit(GETTING_CAT);
            commit(GETTING_SUB);
            commit(GETTING_CLA);

            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getConsignmentNoteCategories( headers);

                response.data.success ? commit(SET_CATEGORIES, response.data.data) : commit(SET_CATEGORIES, [])
               
                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async getSubcategories({ commit }, payload) {
            commit(GETTING_SUB);
            commit(GETTING_CLA);

            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getConsignmentNoteSubcategories(payload, headers);

                response.data.success ? commit(SET_SUBCATEGORIES, response.data.data) : commit(SET_SUBCATEGORIES, [])
               
                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async getClasses({ commit }, payload) {
            commit(GETTING_CLA);

            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getConsignmentNoteClasses(payload, headers);

                response.data.success ? commit(SET_CLASSES, response.data.data) : commit(SET_CLASSES, [])

                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async getCarriers({ commit }, payload) {
            commit(GETTING_CAR);

            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getCarriers(payload, headers);
                  
                response.data.success ? commit(SET_CARRIERS, response.data.data) : commit(SET_CARRIERS, [])
               
                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async getQuotations({ commit }, payload) {
            commit(GETTING_QUO);

            try {               
                const token = userService.getToken();
                const headers = { Authorization: token };
                const response = await api.getQuotations(payload, headers);

                response.data.success ? commit(SET_QUOTATIONS, response.data.data) : commit(SET_QUOTATIONS, [])

                return response.data;
            } catch (error) {
                if(!error?.response?.data?.message) return  ERROR_OBJ;
                return getErrorMessage(error.response.data.message)
            }
        },

        async deleteItemSmt({ commit }, payload) {
            commit(SET_STATUS, "SUCCESS");
            try {
              const token = userService.getToken();
              const headers = { Authorization: token };
              const response = await api.deleteItem(payload, headers);
      
              return response.data;
            } catch (error) {
              if(!error?.response?.data?.message) return  ERROR_OBJ;
              return getErrorMessage(error.response.data.message)
            }
          },

          async editItemSmt({ commit }, payload) {
            commit(SET_STATUS, "SUCCESS");
            try {
              const token = userService.getToken();
              const headers = { Authorization: token };
              const response = await api.editItem(payload, headers);
      
              return response.data;
            } catch (error) {
              if(!error?.response?.data?.message) return  ERROR_OBJ;
              return getErrorMessage(error.response.data.message)
            }
          },

          async addItemSmt({ commit }, payload) {
            commit(SET_STATUS, "SUCCESS");
      
            try {
              const token = userService.getToken();
              const headers = { Authorization: token };
              const response = await api.addItem(payload, headers);
      
              return response.data;
            } catch (error) {
              if(!error?.response?.data?.message) return  ERROR_OBJ;
              return getErrorMessage(error.response.data.message)
            }
          },

    },
}