"use strict";

const local ='http://localhost:3000'
const cpanel = 'https://katherinecosmeticos.com'

exports.url = cpanel + '/api';

const CONSTANTS = {
    GETTING_INFO : "GET_INFO",
    GET_INFO_SUCCESS : "GET_INFO_SUCCESS",
    GETTING_IMG : "GET_IMG",
    GET_IMG_SUCCESS : "GET_IMG_SUCCESS",
    SET_HISTORY_OPTION : "SET_HISTORY_OPTION",
    SET_ADDED : "SET_ADDED",
    SET_STATUS : "SET_STATUS",
    GETTING_MSG : "GETTING_MSG",
    GET_MSG : "GET_MSG",
    ERROR_MSG: "Ha ocurrido un error al intentar realizar la solicitud",
    SUCCESS_MSG: "Mensaje enviado con éxito",
    ERROR_STATUS: "error",
    SUCCESS_STATUS: "success",
    ERROR_INPUTS: "Campos no válidos",
    SUCCES_SUSCRIPTION: "Suscripción exitosa",
    SET_TOKEN: "SET_TOKEN", 
    SET_IDENTITY: "SET_IDENTITY", 
    GET_INFO_IDENTITY: "GET_INFO_IDENTITY",
    CLEAN_VALUE: "CLEAN_VALUE",
    SUCCESS_UPD: "Actualización realizada con éxito",
    SET_CTK: "SET_CTK",
    SET_CAR: "SET_CAR",
    EMAIL_FORMAT: "Email formato no valido",
    GETTING_CAT: 'GETTING_CAT',
    GETTING_SUB: 'GETTING_SUB',
    GETTING_CLA: 'GETTING_CLA',
    GETTING_PAC: 'GETTING_PAC',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_SUBCATEGORIES : 'SET_SUBCATEGORIES',
    SET_CLASSES: 'SET_CLASSES',
    SET_PACKAGINGS: 'SET_PACKAGINGS',
    GETTING_CAR: 'GETTING_CAR',
    GETTING_QUO: 'GETTING_QUO',
    SET_CARRIERS: 'SET_CARRIERS',
    SET_QUOTATIONS: 'SET_QUOTATIONS'
}
exports.CONSTANTS = CONSTANTS;

function getErrorMessage(message) {
  if(!message) return createError(CONSTANTS.ERROR_MSG);

  if (Array.isArray(message)) {
    const stringErrors = message.map(err => err.msg).join(', ')
    return createError(stringErrors, message)
  } else if (typeof message === 'string') {
    return createError(message);
  } else {
    return createError(CONSTANTS.ERROR_MSG);
  }
}

function createError(message_error, detail = []){

  return  {
      success: false,
      status: CONSTANTS.ERROR_STATUS,
      code: 500,
      message: message_error,
      data: null,
      detail
  }
}

exports.getErrorMessage = getErrorMessage;


function roundNum( value ) {
  if(typeof value !== 'number') return 0
  return Math.round(value * 100) / 100
}

exports.roundNum = roundNum;

const costoEnvio = 200
exports.costoEnvio = costoEnvio