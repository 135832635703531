<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  async created(){
    await this.getIdentity()
    const tokenCart = localStorage.getItem("_ctk");
    if (!tokenCart) {
      const userId = this.identity.id ? this.identity.id : null;
      await this.setCart({
        option: "set_cart",
        item: { userId },
      });
    }
    await this.getCart()
    if(this.carrito?.user?.id === null && this.identity.id){
      this.carrito.user.id = this.identity.id
      this.carrito.user.email = this.identity.email
      await this.editItemCrt({
          option: "upt_item",
          item: {
              carrito: this.carrito
          }
      })
    }
  },
  computed:{
    identity() {
        return this.$store.getters['admin/getIdentity'];
    },
    carrito() {
        return this.$store.getters["carrito/getcar"];
    },
  },
  methods: {
    ...mapActions("carrito", ["getCart","setCart", "editItemCrt"]),
    getIdentity: async function () {
      const response = await this.$store.dispatch("admin/getData");
      return response
    },

  },
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:host{
    --swiper-theme-color: #000;
}

textarea#descrp{
  border: 0.06944444444444445vw solid #DBDBDB;
  border-radius: 0.3472222222222222vw;
  padding: .5rem;
  box-sizing: border-box;
  width: 35.52777777777778vw;
  height: 10rem;
}
P.descript{
  width: 21.52777777777778vw;
  margin-bottom: .4rem;
}
.box_value{
  display: flex;
  align-items: center;
}
.box_value p {
  margin-right: .5rem;
}
.cargando{
  background-color: #ED5D8F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5vw;
}
.cargando img{
   height: 50%;
}
.loaderSpinner{
  margin: auto;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ED5D8F;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

p.process{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--BLACK, #282828);
    font-family: Barlow;
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1vw;
    text-transform: uppercase;
    letter-spacing: 2px;
}
    
@media (max-width: 834px) {
  .cargando{
    height:3vw;
  }
  p.process{
      font-size: 2vw;
      margin-top: 2vw;
      padding-bottom: 10vw;
  }
}
@media (max-width: 530px) {
  .cargando{
    height:5vw;
  }
  p.process{
      font-size: 4vw;
      margin-top: 5vw;
      padding-bottom: 10vw;
  }
}
button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

button[disabled] {
  cursor: auto;
}

picture {
  display: flex;
  width: fit-content;
  height: fit-content;
}

a {
  text-decoration: none;
}

dialog {
  min-width: 100%;
  min-height: 100%;
  background-color: transparent;
  border: 0;
}

dialog[open] {
  display: grid;
  place-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s;
}

:root {
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --opacity: rgba(255, 255, 255, 0.6);
}

#app {
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #ED5D8F;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #ED5D8F;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.cursorp {
  cursor: pointer;
}

p.err_msg {
  color: #A1D469 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.brdr {
  height: 2px;
  background-color: #ccc;
  width: 70.7vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

p.subttl {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: 1vw;
}

.carg_btns p {
  background-color: #ED5D8F;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1vw;
}

.carg_btns p img {
  width: 2.5vw;
}

.tbl_min {
  width: fit-content;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.mrtp {
  margin-top: 1vw !important;
}

.tbl_min_hdr {
  display: flex;
  align-items: center;
}

.tbl_min_hdr p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  color: #ffffff;
  font-family: 'DM Sans';
  background-color: var(--color-1);
  border-right: 1px solid white;
  margin: 0vw;
  padding: .3vw 0vw;
}

.tbl_min_row {
  display: flex;
  align-items: center;
  margin-top: .5vw;
}

.tbl_min_row p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  font-size: .8vw;
  font-family: 'DM Sans';
  margin: 0vw;
}

p.tblm1 {
  width: 15vw;
}

p.tblm2 {
  width: 6vw;
}

p.tblm3 {
  width: 10vw;
}

p.tblm4 {
  width: 6vw;
}

p.tblm5 {
  width: 6vw;
}

p.tblm6 {
  width: 8vw;
}

p.tblm7 {
  width: 8vw;
}

p.tblm7 img {
  cursor: pointer;
  width: .8vw;
}

p.bg-grey {
  background-color: #242424;
}

fieldset {
  border: 0;
}

textarea {
  resize: none;
}
</style>
