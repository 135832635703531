import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

  // {
  //   path: '/',
  //   name: 'proximamente',
  //   component: () => import('../views/Proximamente'),
  //   meta: {
  //     log: false,
  //     admin: false,

  //     auth: false,
  //   }
  // },
  {
    path: '/',
    name: 'inicio',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/productos',
    name: 'productos',
    component: () => import('../views/productList'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/producto',
    name: 'producto',
    component: () => import('../views/productView'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,

      auth: false
    }
  },

  {
    path: '/mi-bolsa',
    name: 'mi-bolsa',
    component: () => import('../views/mi_bolsa/mi_bolsa.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/mi-cuenta',
    name: 'mi-cuenta',
    component: () => import('../views/miCuentaWrapper.vue'),
    meta: {
      log: true,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/detalles-pago',
    name: 'detallespago',
    component: () => import('../views/pago/detallesPago.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/adminWrapper'),
    meta: {
      log: true,
      admin: true,

      auth: true,
    }
  },
  {
    path: '/aviso-privacidad',
    name: 'Aviso de provacidad',
    component: () => import('../views/AvisoPrivacidad'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '/terminos-condiciones',
    name: 'Terminos Y Condiciones',
    component: () => import('../views/TerminosCondiciones'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '/pago-exitoso',
    name: 'succesPayment',
    component: () => import('../views/pago/succesPayCard.vue'),
    meta: {
      log: true,
      admin: false,

      auth: false
    }
  },
  {
    path: '/pago-curso-exitoso',
    name: 'succesPayment',
    component: () => import('../views/pago/succesPayCardCurso.vue'),
    meta: {
      log: true,
      admin: false,

      auth: false
    }
  },
  {
    path: '/pedido-exitoso',
    name: 'succesPedidoTransf',
    component: () => import('../views/pago/succesOrderTransf.vue'),
    meta: {
      log: true,
      admin: false,

      auth: false
    }
  },

  /*
  {
    path: '/pago-exitoso-invitado',
    name: 'succesPayment-invitado',
    component: () => import('../views/pago/succesPayCard-Invitado.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },

  {
    path: '/pedido-exitoso-invitado',
    name: 'succesPedidoTransf-invitado',
    component: () => import('../views/pago/succesOrderTransf-Invitado.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },*/
  {
    path: '/cursos',
    name: 'Cursos Web',
    component: () => import('../views/Cursos'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '/curso',
    name: 'Cursos Web',
    component: () => import('../views/cursoView.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '/iniciar-sesion',
    name: 'iniciar sesion',
    component: () => import('../views/IniciarSesion'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },


]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async (to, from, next) => {
  const meta_admin = to.matched.some(record => record.meta.admin);
  const meta_log = to.matched.some(record => record.meta.log);
  const meta_auth = to.matched.some(record => record.meta.auth);
  const tokenexp = await userService.tokenexp()
  let check = false
  let admin = false;
  let type = ''
  if (tokenexp) {
    localStorage.removeItem('tkn')
    next()
  }

  const response = await userService.getData()

  if (response.status == 'error') {
    localStorage.removeItem('tkn')
  }

  if (response.success) {
    check = true;
    let isAdmin = userService.isAdmin(response.data);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  const ath = await userService.checkAuth();

  if ((to.path === '/login' || to.path === '/iniciar-sesion') && check) {
    localStorage.removeItem('tkn')
    next()
  }

  if (!meta_log) next();
  if (meta_log && !check) {
    next('/iniciar-sesion');
  }

  if (meta_admin) {
    if (admin) {
      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          next('/login')
        }
      } else {
        next()
      }
    } else {
      localStorage.removeItem('tkn')
      next('/')
    }
  } else {
    next()
  }


});





export default router

