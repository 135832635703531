"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS, getErrorMessage, url, roundNum } from "../../global/index";
const {
  SET_ADDED,
  ERROR_MSG,
  ERROR_STATUS,
  SET_CTK,
  SET_CAR,
  SUCCESS_STATUS
} = CONSTANTS;

const ERROR_OBJ = {
    success: false,
    status: ERROR_STATUS,
    code: 500,
    message: ERROR_MSG,
    data: null
  };


export default {
  namespaced: true,
  state: {
    _ctk: "",
    car: "",
    added: "",
  },
  getters: {
    getAdded(state) {
      return state.added
    },

    getcar(state) {
      return state.car
    },
    gettkn(state) {
      return state._ctk
    },
  },
  mutations: {
    [SET_ADDED](state, change) {
      state.added = change
    },

    [SET_CAR](state, change) {
      state.car = change
    },
    [SET_CTK](state, change) {
      state._ctk = change
    },
  },
  actions: {
    //Asignar el objeto de carrito
    setCarrito({ commit }, payload) {
      commit(SET_CAR, payload)
    },

    setToken({ commit }, payload) {
      commit(SET_CTK, payload)
    },

    setAddedCrt({ commit }, payload) {
      commit(SET_ADDED, payload)
    },

    async setCart({ commit }, payload) {
      localStorage.removeItem("_ctk");
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        if (response.data.success) {
          commit(SET_CAR, response.data.car)
          commit(SET_CTK, response.data.token)
          localStorage.setItem("_ctk", response.data.token)
        } else {
          commit(SET_CAR, "")
          commit(SET_CTK, "")
        }

        return response.data
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async getCart({ commit }) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }

        let _ctk = localStorage.getItem("_ctk")
        if(!_ctk || _ctk === '' || _ctk === null){
          const token = userService.getToken()
          const headers = { Authorization: token }
          const response = await api.addItem({
            option: "set_cart",
            item: { userId: null },
          }, headers)
  
          if (response.data.success) {
            commit(SET_CAR, response.data.car)
            commit(SET_CTK, response.data.token)
            localStorage.setItem("_ctk", response.data.token)
            _ctk = response.data.token
          } else {
            window.location.reload();
          }
        }


        const payload = {
          option: "get_cart",
          item: { _ctk: _ctk },
        }
        const response = await api.addItem(payload, headers)

        if (response.data.success) {
          commit(SET_CAR, response.data.carrito)
          commit(SET_CTK, _ctk)
        } else {
          commit(SET_CAR, "")
          commit(SET_CTK, "")
          localStorage.removeItem("_ctk")
        }

        return response.data
      } catch (error) {
        commit(SET_CAR, "")
        commit(SET_CTK, "")
        localStorage.removeItem("_ctk")
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async deleteItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.deleteItemCar(payload, headers)
        if (response.data.success) {
          commit(SET_CTK, response.data.data)
          commit(SET_CAR, response.data.carrito)
          localStorage.setItem("_ctk", response.data.data)
        }

        return response.data
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async editItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.editItem(payload, headers)
        
        if (response.data.success) { //aqui se espera que el response devuelva el success, pero esta devolviendo un status. response.data.status
          commit(SET_CTK, response.data.data)
          commit(SET_CAR, response.data.carrito)
          localStorage.setItem("_ctk", response.data.data)
        }

        return response.data
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async addItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        if (response.data.success) {
          commit(SET_CTK, response.data.data)
          commit(SET_CAR, response.data.carrito)
          localStorage.setItem("_ctk", response.data.data)
        }

        return response.data
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    async validateCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        return response.data
      } catch (error) {
        if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
      }
    },

    updateCart({commit}, payload){
      const { clone, new_quantity, index, remove } = payload

      if(!Array.isArray(clone.items) || clone?.subtotal === undefined) return getErrorMessage('Carrito no valido')
      if(new_quantity === undefined || index === undefined || remove === undefined) return getErrorMessage(ERROR_MSG)
    
      if(!remove){
          const new_subtotal = Number(clone.items[index].unitPrice) * new_quantity
          clone.items[index].quantity = roundNum(new_quantity)
          clone.items[index].subtotal = roundNum(new_subtotal)
      }
      const sumItems = clone.items.reduce((total, item) => {
      return total + Number(item.subtotal)
      }, 0)
      clone.subtotal = roundNum(sumItems)
      const total = clone.subtotal + clone.envio - clone.descoupon
      clone.total = roundNum(total)

      return {
          success: true,
          status: SUCCESS_STATUS,
          data:clone
      }
  },

   async createBodyAddToCart({ commit }, payload){
      const { producto, selectedVariantId = undefined, fullPath, fromCart = undefined, quantity = 1 } = payload

      let ctk = localStorage.getItem('_ctk')
      if(!ctk || ctk === '' || ctk === null){
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem({
          option: "set_cart",
          item: { userId: null },
        }, headers)

        if (response.data.success) {
          commit(SET_CAR, response.data.car)
          commit(SET_CTK, response.data.token)
          localStorage.setItem("_ctk", response.data.token)
          ctk = response.data.token
        } else {
          window.location.reload();
        }
      }

      if(fromCart !== undefined){ //el item viene con la estructura del carrito.
        if(!producto.productId) return getErrorMessage('Producto no valido')
        const data = { //producto variable
            _ctk: ctk,
            productId: producto.productId,
            product: producto,
            quantity: quantity,
            unitPrice: Number(producto.unitPrice),
            subtotal: Number(producto.unitPrice) * Number(quantity),
            url: producto.url
        } 
        if(producto?.productVariantId !== undefined){
          data.productVariantId = producto.productVariantId
        }

        return {
          success: true,
          status: SUCCESS_STATUS,
          data
        }
      }

      //el item viene desde otra vista con una estructura diferente
      if(producto?.simpleProduct === undefined) return getErrorMessage('Producto no valido')
      if(producto.simpleProduct === false){
        if(!selectedVariantId) return getErrorMessage('Variante no seleccionada')
        const find = producto.variants.find(item => item.id === selectedVariantId)

        if(!find) return getErrorMessage('Variante no encontrada')

        const getUnitPrice = find.discountPrice === null ? Number(find.price) : Number(find.discountPrice)

        const data = {
            _ctk: ctk,
            productId: find.productId,
            productVariantId: find.id,
            product: find,
            quantity: quantity,
            unitPrice: getUnitPrice,
            subtotal: getUnitPrice * Number(quantity),
            url: url + String(fullPath)
        } 
        return {
          success: true,
          status: SUCCESS_STATUS,
          data
        }
      }
  

      const getUnitPrice = producto.discountPrice === null ? Number(producto.price) : Number(producto.discountPrice)

      const data = {
          _ctk: ctk,
          productId: producto.id,
          product: producto,
          quantity: quantity,
          unitPrice: getUnitPrice,
          subtotal: getUnitPrice * Number(quantity),
          url: url + String(fullPath)
      }
  
      return {
        success: true,
        status: SUCCESS_STATUS,
        data
      }
    }

  },
}
